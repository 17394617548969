import {Link} from "gatsby";
import * as React from "react";
import {Helmet} from "react-helmet";

import Footer from "../components/Footer/Footer";
import Header from "../components/HeaderNew";
import MainIcon from "../components/icons/404";
import "./404.scss";

const NotFoundPage = () => {
  return (
    <>
      <Helmet title='Not found' htmlAttributes={{lang: "en"}} />
      <Header type='mason' />
      <main className='not-found'>
        <section>
          <MainIcon />
          <h1>Uh oh. That page doesn’t exist.</h1>
          <p>
            Head to our <Link to='/'>homepage</Link> or just talk to us on chat
            below
          </p>
        </section>
        <Footer />
      </main>
    </>
  );
};

export default NotFoundPage;
